import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCredores(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('credores', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCredor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`credores/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCredor(ctx, credorData) {
      return new Promise((resolve, reject) => {
        axios
          .post('credores', credorData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
