<template>
  <b-card
    no-body

  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
          Usuário para acesso
      </h5>
      <b-badge variant="light-primary">
        LOGIN
      </b-badge>
    </b-card-header>

    <b-card-body >
      <ul class="list-unstyled my-1">
        <li>
          <span class="align-middle"><strong>Código Login: </strong>{{ credorData.data.usuario }}</span>
        </li>
         <li>
          <span class="align-middle"><strong>Senha: </strong>
            <span v-if="credorPass == null">********</span>
            <h4 v-else> 
              <b-badge variant="success">
                {{ credorPass }}
              </b-badge> </h4>
          </span>
        </li>
      </ul>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        block
        v-b-modal.modal-password
      >               
        <feather-icon
          icon="LockIcon"
          class="mr-50"
        />
        <span class="align-middle">Alterar minha senha</span> 
      </b-button>
    </b-card-body>

    <!-- modal password-->
    <b-modal
      id="modal-password"
      ref="modal-password"
      cancel-variant="outline-secondary"
      ok-title="Confirmar"
      cancel-title="Cancelar"
      centered
      title="Alterar minha senha"
      @ok="handleFormModal"
      @hidden="resetForm"
    >
      <validation-observer
        ref="refFormObserver"
      > 
        <b-form ref="formPass">
          <validation-provider
              #default="{ errors }"
              name="Password"
              vid="Password"
              rules="required|min:6"
          >
            <b-form-group>
              <label for="pass">Informar nova senha:</label>
              <b-form-input
                v-model="newPass"
                id="pass"
                type="password"
                placeholder="Senha"
                :state="errors.length > 0 ? false:null"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Password Confirm"
            rules="required|confirmed:Password"
          >
            <b-form-group>
              <label for="new_pass">Confirmar senha:</label>
              <b-form-input
                v-model="confirmPass"
                id="new_pass"
                type="password"
                placeholder="Confirmar senha"
                :state="errors.length > 0 ? false:null"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BModal,BForm, BFormGroup,BFormInput
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, confirmed, min
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref }  from '@vue/composition-api'
import axios from '@axios'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    credorData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const credorPass = ref(null)
    const newPass = ref(null)
    const confirmPass = ref(null)

    function handleFormModal(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.alterarSenha()
    }

    function alterarSenha(){

      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          axios.post('api-credor/alterarSenha',{
            credor_id: props.credorData.data.id,
            new_pass: newPass.value
          })
          .then(response => {             
            this.$nextTick(() => {
              this.$refs['modal-password'].toggle('#toggle-btn')
            })
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Alteração de senha',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tudo certo, sua senha foi alterada! Em seu próximo login utilize ela.',
              },
            })
          })
          .catch(error => console.log(error)) 
        }else{
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Alteração de senha',
              icon: 'XIcon',
              variant: 'danger',
              text: 'Preencha as informações corretamente para alterar sua senha.',
            },
          })
        }
      })       
    }
    
    function resetForm() {
      newPass.value = null
      confirmPass.value = null
    }

    return {
      credorPass,
      newPass,
      confirmPass,
      handleFormModal,
      alterarSenha,
      resetForm,

      required,
      confirmed,
      min
    }
  },
}
</script>

<style>

</style>
