<template>
  <b-sidebar
    id="add-new-titulo-sidebar"
    :visible="isAddNewTituloSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="50rem"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-titulo-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar novo dívida
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >               
          <h6>Informações do devedor</h6>
          <hr>

          <!-- Devedor -->
          <validation-provider
            #default="validationContext"
            name="Devedor"
            :rules="`${showFormDevedor ? '' : 'required'}`"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              v-if="!showFormDevedor"
            >
              <h5>Selecione um <u>devedor já existente</u></h5>
              <v-select
                v-model="tituloData.cliente_id"
                :options="clientes"
                :clearable="false"
                input-id="devedor"
                label="nome"
                placeholder="Pesquisar..."
                class=""
                :reduce="cliente => cliente.id"
                @search="onSearch"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <h5><span class="text-muted">ou clique aqui para</span> <b-link @click="handleFormDevedor(true)">criar novo devedor</b-link></h5>

          <!-- FORM DEVEDOR -->
          <div v-if="showFormDevedor">
            <!-- Nome -->
            <validation-provider
              #default="validationContext"
              name="Nome"
              :rules="`${showFormDevedor ? 'required' : ''}`"
            >
              <b-form-group
                label="Nome *"
                label-for="nome"
              >
                <b-form-input
                  id="nome"
                  v-model="clienteData.nome"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="João da Silva"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Tipo Pessoa e Documento -->
            <b-row>
              <b-col>
                <!-- Tipo Pessoa -->
                <validation-provider
                  #default="validationContext"
                  name="Tipo Pessoa"
                  :rules="`${showFormDevedor ? 'required' : ''}`"
                >
                  <b-form-group
                    label="Tipo Pessoa *"
                    label-for="tipo_pessoa"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="clienteData.tipo_pessoa"
                      :options="tipoPessoaOptions"
                      :reduce="val => val.value"
                      :clearable="false"
                      input-id="tipo_pessoa"
                      state="false"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <!-- Documento -->
                <validation-provider
                  #default="validationContext"
                  name="Documento"
                  :rules="`${showFormDevedor ? 'required' : ''}`"
                >
                  <b-form-group
                    label="Documento *"
                    label-for="documento"
                  >
                    <b-form-input
                      id="documento"
                      v-model="clienteData.documento"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>    
              </b-col>
            </b-row>

            <!-- Código -->
            <validation-provider
              #default="validationContext"
              name="Codigo"
              rules="alpha-num"
            >
              <b-form-group
                label="Codigo Interno"
                label-for="codigo"
              >
                <b-form-input
                  id="codigo"
                  v-model="clienteData.codigo"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group> 
            </validation-provider>

            <!-- Telefone -->
            <validation-provider
              #default="validationContext"
              name="Telefone"
              rules=""
            >
              <b-form-group
                label="Telefone"
                label-for="telefone"
              >
                <b-form-input
                  id="telefone"
                  v-model="clienteData.telefone"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group> 
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="clienteData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group> 
            </validation-provider>

            <hr class="mt-2">
            <h6>Endereço</h6>

            <!-- CEP e Logradouro -->
            <b-row>
              <b-col md="3">
                <!-- CEP -->
                <validation-provider
                  #default="validationContext"
                  name="CEP"
                  rules=""
                >
                  <b-form-group
                    label="CEP"
                    label-for="cep"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      id="cep"
                      v-model="clienteData.cep"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="9">
                <!-- Logradouro -->
                <validation-provider
                  #default="validationContext"
                  name="Logradouro"
                  rules=""
                >
                  <b-form-group
                    label="Logradouro"
                    label-for="logradouro"
                  >
                    <b-form-input
                      id="logradouro"
                      v-model="clienteData.logradouro"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>    
              </b-col>
            </b-row>

            <!-- Numero / Complemento / Bairro -->
            <b-row>
              <b-col>
                <!-- Numero -->
                <validation-provider
                  #default="validationContext"
                  name="Numero"
                  rules=""
                >
                  <b-form-group
                    label="Numero"
                    label-for="numero"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      id="numero"
                      v-model="clienteData.numero"
                      :state="getValidationState(validationContext)"
                      trim
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <!-- Complemento -->
                <validation-provider
                  #default="validationContext"
                  name="Complemento"
                  rules=""
                >
                  <b-form-group
                    label="Complemento"
                    label-for="complemento"
                  >
                    <b-form-input
                      id="complemento"
                      v-model="clienteData.complemento"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>    
              </b-col>
              <b-col>
                <!-- Bairro -->
                <validation-provider
                  #default="validationContext"
                  name="Bairro"
                  rules=""
                >
                  <b-form-group
                    label="Bairro"
                    label-for="bairro"
                  >
                    <b-form-input
                      id="bairro"
                      v-model="clienteData.bairro"
                      :state="getValidationState(validationContext)"
                      trim
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>    
              </b-col>
            </b-row>

            <!-- Estado e Cidade -->
            <b-row>
              <b-col>
                <!-- Estado -->
                <validation-provider
                  #default="validationContext"
                  name="Estado"
                  rules=""
                >
                  <b-form-group
                    label="Estado"
                    label-for="estado"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="clienteData.uf_id"
                      :options="estadosOptions"
                      :reduce="val => val.id"
                      input-id="estado"
                      label="nome"
                      @input="changeEstado"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <!-- Cidade -->
                <validation-provider
                  #default="validationContext"
                  name="Cidade"
                  rules=""
                >
                  <b-form-group
                    label="Cidade"
                    label-for="cidade"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="clienteData.cidade_id"
                      :options="cidadesOptions"
                      :reduce="val => val.id"
                      input-id="cidade"
                      label="nome"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-danger"
                size="sm"
                @click="handleFormDevedor(false)"
              >
                Cancelar
              </b-button>
            </div>
          </div>

          <h6 class="mt-3">Informações do título</h6>
          <hr>         
          <!-- Vencimento -->
          <validation-provider
            #default="validationContext"
            name="Vencimento"
            rules="required"
          >
            <b-form-group
              label="Vencimento *"
              label-for="vencimento"
            >
              <b-form-input
                id="valor"
                type="date"
                v-model="tituloData.vencimento"
                :state="getValidationState(validationContext)"                
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Valor -->
          <validation-provider
            #default="validationContext"
            name="Valor"
            rules="required"
          >
            <b-form-group
              label="Valor *"
              label-for="valor"
            >
              <b-input-group prepend="R$">
                <b-form-input
                  id="valor"
                  v-model="tituloData.valor"
                  :state="getValidationState(validationContext)"
                  trim
                />
              </b-input-group>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group> 
          </validation-provider>

           <!-- Numero do Titulo -->
          <validation-provider
            #default="validationContext"
            name="Numero do Titulo"
            rules=""
          >
            <b-form-group
              label="Número do Título"
              label-for="numero_titulo"
            >
              <b-form-input
                id="numero_titulo"
                v-model="tituloData.numero_titulo"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="102030"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Referencia -->
          <validation-provider
            #default="validationContext"
            name="Referencia"
            rules=""
          >
            <b-form-group
              label="Referência"
              label-for="referencia"
            >
              <b-form-input
                id="referencia"
                v-model="tituloData.referencia"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>         
                 
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Adicionar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroup, BLink, BRow, BCol
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { ref, onBeforeMount,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import axios from '@axios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BLink,
    BRow,
    BCol,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewTituloSidebarActive',
    event: 'update:is-add-new-titulo-sidebar-active',
  },
  props: {
    isAddNewTituloSidebarActive: {
      type: Boolean,
      required: true,
    },
    credorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email
    }
  },
  setup(props, { emit }) {
    const blankTituloData = {
      numero_titulo: null,
      vencimento: null,
      valor: null,
      referencia: null,
      situacao: null,
      cliente_id: null,
    }

    const blankClienteData = {
      nome: null,
      tipo_pessoa: 'PF',
      documento: null,
      codigo: null,
      situacao: 'ATIVO',
      telefone: null,
      email: null,
      cep: null,
      logradouro: null,
      numero: null,
      bairro: null,
      complemento: null,
      uf_id: null,
      cidade_id: null,
    }

    const tipoPessoaOptions = [
      { label: 'Pessoa Física', value: 'PF' },
      { label: 'Pessoa Jurídica', value: 'PJ' }
    ]

    const estadosOptions = ref([])
    const cidadesOptions = ref([])

    const clientes = ref([])
    const showFormDevedor = ref(false)

    const tituloData = ref(JSON.parse(JSON.stringify(blankTituloData)))
    const clienteData = ref(JSON.parse(JSON.stringify(blankClienteData)))

    const resetTituloData = () => {
      tituloData.value = JSON.parse(JSON.stringify(blankTituloData))
      clienteData.value = JSON.parse(JSON.stringify(blankClienteData))
    }

    const changeEstado = (value) => {
      if(value != null){
        fetchCidades(value)
      }else{
        cidadesOptions.value = []
      }
    }

    const fetchCidades = (id) => {
      axios
      .get('estado/'+id+'/cidades')
      .then(response => {
        cidadesOptions.value = response.data
      })
      .catch(error => reject(error))  
    }

    const onSubmit = () => {
      axios
      .post('api-credor/adicionarTitulo', {
        credor_id: props.credorId,
        ...tituloData.value,
        clienteData: {
          ...clienteData.value
        }
      })
      .then(response => {
        emit('update:is-add-new-titulo-sidebar-active', false)
        emit('refreshData')
      })
      .catch(error => reject(error))  
    }

    onBeforeMount(() => {
      axios
      .get('api-credor/'+props.credorId+'/filtroClientes', {
        params: {
          busca: null,
          credor_id: props.credorId
        }
      })
      .then(response => {
        const { data } = response.data  
        clientes.value = data
      })
      .catch(error => reject(error))  

      axios
      .get('estados')
      .then(response => {
        estadosOptions.value = response.data
      })
      .catch(error => reject(error)) 
    })

    const debounce = (fn, delay) => {
      let timeout

      return (...args) => {
        if (timeout) {
          clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
          fn(...args)
        }, delay)
      }
    }
    
    const onSearch = debounce((text,loading) => {
      loading(true)
      axios
        .get('api-credor/'+props.credorId+'/filtroClientes', {
          params: {
            busca: text,
            credor_id: props.credorId
          }
        })
        .then(response => {
          const { data } = response.data  
          clientes.value = data
        })
        .catch(error => reject(error))  
        .finally(() => loading(false))    
    }, 500)

    function handleFormDevedor(param) {
      showFormDevedor.value = param
      tituloData.value.cliente_id = null
      clienteData.value = JSON.parse(JSON.stringify(blankClienteData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetTituloData)

    return {
      tipoPessoaOptions,
      tituloData,
      clienteData,
      clientes,
      onSubmit,
      onSearch,
      debounce,
      showFormDevedor,
      handleFormDevedor,
      estadosOptions,
      cidadesOptions,

      changeEstado,
      fetchCidades,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
// #add-new-titulo-sidebar{
//     width: 50rem;
// }
</style>
