<template>

  <div>

    <adicionar-divida
      :is-add-new-titulo-sidebar-active.sync="isAddNewTituloSidebarActive"
      :credor-id="credorId"
      @refreshData="refreshData"
    />

    <b-card
      no-body
      class="card-statistics"
    >
      <b-card-header class="pb-0">
        <b-card-title>Dívidas</b-card-title>
      </b-card-header>
      <b-overlay
        :show="showLoading"
        variant="transparent"
        opacity="1"
        blur="2px"
        rounded="sm"
      >
        <b-card-body class="statistics-body" v-if="statsData">
          <b-row class="mb-3">
            <b-button
              variant="primary"
              block
              size="lg"
              @click="isAddNewTituloSidebarActive = true"
            >            
              <span class="align-middle">ADICIONAR NOVA DÍVIDA</span>            
              <feather-icon
                icon="PlusSquareIcon"
                class="ml-50"
                size="25"
              />
            </b-button>
          </b-row>
          <hr>
          <b-row class="mt-3">
            <!-- Devedores -->
            <b-col
              xl="6"
              sm="6"
              class="mb-2 mb-sm-0"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-info"
                  >
                    <feather-icon
                      size="24"
                      icon="UserIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ statsData.count_devedores }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Devedores
                  </b-card-text>
                  <b-button
                    class="mt-2"
                    size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="relief-info"
                    :to="{ name: 'cliente-credor'}"
                  >
                    Visualizar Devedores
                  </b-button>
                </b-media-body>
              </b-media>
            </b-col>
            <!-- Titulos -->
            <b-col
              xl="6"
              sm="6"
              class="mb-2 mb-sm-0"
            >
              <b-media no-body>
                <b-media-aside
                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-danger"
                  >
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ statsData.count_titulos }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    Títulos
                  </b-card-text>
                  <b-button
                    class="mt-2"
                    size="sm"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="relief-danger"
                    :to="{ name: 'titulo-credor'}"
                  >
                    Visualizar Títulos
                  </b-button>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BButton, BOverlay
} from 'bootstrap-vue'
import axios from '@axios'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import AdicionarDivida from './AdicionarDivida.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BButton,
    BOverlay,
    AdicionarDivida
},
  props: {
    credorId: {
      type: Number,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {

    const statsData = ref(null)
    const showLoading = ref(true)

    const isAddNewTituloSidebarActive = ref(false)

    axios.get('credor/'+props.credorId+'/estatisticas')
      .then(response => { statsData.value = response.data; showLoading.value = false })
      .catch(error => console.log(error))
    
    function refreshData(){
      showLoading.value = true
      axios.get('credor/'+props.credorId+'/estatisticas')
      .then(response => { statsData.value = response.data; showLoading.value = false })
      .catch(error => console.log(error))
    }

    return {
      statsData,
      showLoading,
      isAddNewTituloSidebarActive,
      refreshData
    }
  },
}
</script>
