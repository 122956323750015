<template>
  <div>

    <template v-if="credorData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <CredorInfoCard :credor-data="credorData" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <CredorUsuario :credor-data="credorData"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <CredorDividas :credor-id="credorId" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <CredorStats :credor-id="credorId" />      
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol
} from 'bootstrap-vue'

import credorStoreModule from '../../credor/credorStoreModule'
import CredorInfoCard from './CredorInfoCard.vue'
import CredorUsuario from './CredorUsuario.vue'
import CredorDividas from './CredorDividas.vue'
import CredorStats from './CredorStats.vue'

import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    // Local Components
    CredorInfoCard,
    CredorUsuario,
    CredorDividas,
    CredorStats
},
  setup() {
    const credorData = ref(null)
    const credorId = ref(null)

    const userData = getUserData()

    const CREDOR_APP_STORE_MODULE_NAME = 'app-credor'

    // Register module
    if (!store.hasModule(CREDOR_APP_STORE_MODULE_NAME)) store.registerModule(CREDOR_APP_STORE_MODULE_NAME, credorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CREDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(CREDOR_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-credor/fetchCredor', { id: userData.credor.id })
      .then(response => { credorData.value = response.data; credorId.value = parseInt(userData.credor.id)})
      .catch(error => {
        if (error.response.status === 404) {
          credorData.value = undefined
        }
      })

    return {
      credorData,
      credorId
    }
  },
}
</script>

<style>

</style>
